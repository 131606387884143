/* eslint-disable spaced-comment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
*/

export * from 'SourceUtil/Product/Extract';

export const DEFAULT_MIN_PRODUCTS = 1;
export const DEFAULT_MAX_PRODUCTS = 999;

export const MIN_SALE_QTY = 'min_sale_qty';
export const MAX_SALE_QTY = 'max_sale_qty';
export const SALABLE_QTY = 'salable_qty';

/** @namespace Satisfly/Util/Product/Extract/getFieldQty */
export const getFieldQty = (product, field) => {
    if (field === MIN_SALE_QTY || field === MAX_SALE_QTY) {
        const { stock_item: { [field]: qty } = {} } = product;

        return qty;
    }

    const { [field]: qty } = product;

    return qty;
};

/**
 * Returns product quantity for specific field (with fallback value)
 * @param product
 * @param defaultValue
 * @param field
 * @param configIndex
 * @returns {*}
 * @namespace Satisfly/Util/Product/Extract/getQuantity */
export const getQuantity = (product, defaultValue, field, configIndex = -1) => {
    if (!product) {
        return defaultValue;
    }

    const qty = getFieldQty(product, field);

    if (!qty) {
        return defaultValue;
    }

    const { variants } = product;

    if ((!configIndex && !variants) || configIndex === -1) {
        return qty;
    }

    const variantQty = getFieldQty(variants[configIndex] || {}, field);

    return variantQty || qty;
};

/**
 * Returns maximum purchasable item quantity.
 * @param product
 * @param configIndex
 * @returns {*}
 * @namespace Satisfly/Util/Product/Extract/getMaxQuantity */
export const getMaxQuantity = (product = {}, configIndex = -1) => {
    const {
        is_preorder: isPreorder = false
    } = product;

    const maxQuantity = getQuantity(product, DEFAULT_MAX_PRODUCTS, MAX_SALE_QTY, configIndex);
    const salableQuantity = getQuantity(product, DEFAULT_MAX_PRODUCTS, SALABLE_QTY, configIndex);

    if (isPreorder) {
        return maxQuantity;
    }

    return Math.min(maxQuantity, salableQuantity);
};
